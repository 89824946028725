// please refer to https://redux-resource.js.org/ for more
// information about how we're using resources in redux.

// specifically about resource actions:
// https://redux-resource.js.org/requests/request-actions
import { actionTypes } from 'redux-resource'
import _ from 'lodash'

import { requestSelector } from './selectors'
import resourceTypes, { resourceTypesHashmap } from './resourceTypes'
import contentService from '../../../services/contentfulService'

const fetchResourceFactory = ({ singular }) => {
  return ({
    resourceType,
    requestKey: passedRequestKey,
    id,
    where,
    select,
    order,
    skip,
    limit,
    next,
    include
  }) => {
    const requestKey = passedRequestKey || resourceType
    const requestPropertiesFromAction = {
      resourceType,
      requestKey,
      id,
      where,
      select,
      order,
      skip,
      limit,
      next,
      include
    }

    return (dispatch, getState) => {
      let actionError
      if (!resourceTypesHashmap[resourceType]) {
        actionError = new Error(
          `resourceType "${resourceType}" is invalid.` +
            `resourceType must be one of: ${resourceTypes.join(', ')}`
        )
      }

      const dispatchError = (error) => {
        dispatch({
          type: actionTypes.READ_RESOURCES_FAILED,
          resourceType,
          resources: singular ? [id] : undefined,
          requestKey,
          requestProperties: {
            errorMessage: error && error.message,
            ...requestPropertiesFromAction
          }
        })
      }

      if (actionError) {
        return dispatchError(actionError)
      }

      const existingRequest = requestSelector(resourceType)(getState())
      const { total } = existingRequest || {}

      // set pending state
      dispatch({
        type: actionTypes.READ_RESOURCES_PENDING,
        resourceType,
        resources: singular ? [id] : undefined,
        requestKey,
        requestProperties: {
          total,
          ...requestPropertiesFromAction
        }
      })

      const fetchFunction = singular
        ? contentService.fetchResource
        : contentService.fetchResources

      if (next) {
        // work out skip value to get next results
        const { resources, limit: existingLimit } = existingRequest || {}
        skip = _.get(resources, 'length')
        if (!limit && existingLimit) {
          limit = existingLimit
        }
      }

      return fetchFunction({
        resourceType,
        id,
        where,
        select,
        order,
        skip,
        limit,
        include
      })
        .then((result) => {
          let newResourcesValue = []

          if (!result) {
            throw new Error('no result')
          }

          // we need to do this because the mergeResources functionality built into redux-resource
          // only merges top-level keys, and we need to merge resource.field objects
          const state = getState()

          const selectExistingResource = (id) => {
            return _.get(state, ['resources', resourceType, 'resources', id])
          }

          const prepareResource = (newResource) => {
            if (newResource && newResource.id) {
              newResource = {
                ...newResource
              }
              const oldResource = selectExistingResource(newResource.id)
              if (oldResource) {
                const mergedResource = {
                  ...newResource,
                  fields: {
                    ...oldResource.fields,
                    ...newResource.fields
                  }
                }
                return mergedResource
              } else {
                return newResource
              }
            } else {
              return newResource
            }
          }

          if (singular) {
            const singleResource = result
            newResourcesValue = [prepareResource(singleResource)]
          } else {
            const { resources } = result
            if (!next) {
              newResourcesValue = _.map(resources, prepareResource)
            } else {
              // append resources from next page to existing resources
              newResourcesValue = [
                ..._.get(existingRequest, 'resources', []),
                ..._.map(resources, prepareResource)
              ]
            }
          }

          dispatch({
            type: actionTypes.READ_RESOURCES_SUCCEEDED,
            resourceType,
            resources: newResourcesValue,
            requestKey,
            requestProperties: {
              ...requestPropertiesFromAction
            },
            mergeResources: false
          })

          return {
            resources: newResourcesValue,
          }
        })
        .catch((error) => {
          dispatchError(error)
        })
    }
  }
}

const fetchResource = fetchResourceFactory({ singular: true })
const fetchResources = fetchResourceFactory({ singular: false })

export { fetchResource, fetchResources }
